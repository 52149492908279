.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  box-shadow: 0 2px 5px rgb(0 0 0 / 20%), 0 0 1px rgb(0 0 0 / 40%);
  color: var(--font-color-1);
  padding-left: 5%;
  padding-right: 5%;
}

.nav-links {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-left: 3rem;
  margin-right: 2rem;
  align-items: center;
}

.user-info {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  font-size: 1rem;
  color: var(--grey);
}

.nav-links-wrap {
  display: flex;
  margin-left: 2rem;
}

.jjt-logo {
  width: 180px;
}

.nav-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.rmr-last-update {
  font-size: 12px;
  position: fixed;
  right: 0;
  bottom: 0;
  background: white;
  text-align: right;
  padding: 2px;
  z-index: 999;
  padding-bottom: 5px;
  padding-right: 10px;
}

@media only screen and (max-width: 1050px) {
  .nav-links {
    gap: 1rem;
  }

  .user-info {
    gap: 2px;
  }

  .nav-links-wrap {
    margin-left: 0.5rem;
  }

  .nav-container {
    padding-left: 2px;
    padding-right: 0.5rem;

  }

}