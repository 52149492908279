.my-resources-controls {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}


.my-resources-container {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-resources-info {
  text-align: center;
  font-size: 0.85rem;
}

.btn-edit {
  border: none;
  background-color: transparent;
  color: var(--green);
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  font-family: inherit;
  font-size: 14px;
  gap: 5px;
}

.btn-edit:hover {
  opacity: 1;
}

.link-resource {
  color: var(--link-color-1);

}

.link-resource:hover {
  text-decoration: underline;
}

.centered-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  gap: 0.3rem;
  font-size: 18px;
}

.rs-span-data {
  gap: 5px;
  display: flex;
  flex-direction: column;
}