.resource-form {
  margin-left: 1rem;
  margin-right: 1rem;

}

.rf-header-table {
  border: 1px solid var(--border-color-1);
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.rf-header-table th {
  border: 1px solid var(--border-color-1);
  border-collapse: collapse;
  padding: 8px;
  text-align: left;
  width: 180px;
  border-right: none;
}

.rf-header-table td {
  border: 1px solid var(--border-color-1);
  border-collapse: collapse;
  padding: 8px;
  border-left: none;
}

.rf-select {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: space-between;
}

.rf-select-wrap {

  width: 32%;
}

.rf-select-name {
  font-weight: 600;
  background-color: var(--blue);
  color: var(--bg-color-1);
  width: 100%;

  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  border-radius: 4px;
  margin-bottom: 5px;
}

.rf-note {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.rf-note-textarea {
  resize: vertical;
  width: 100%;
  border: 1px solid var(--border-color-1);
  min-height: 115px;
  padding: 10px;
  border-radius: 5px;
}

.rf-nav-btns {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
  align-items: center;
}

.rf-select-placeholder-div {
  height: 38px;
  border-radius: 4px;
  border: 1px solid var(--border-color-1);
  display: flex;
  justify-content: center;
}

/* this is for react-select component  */
.rf-notification {
  color: var(--green);
}


.resource-sow-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.resource-sow-section-title {
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 500;
}

.resource-sow-select {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.resource-sow-table {
  margin-bottom: 2rem;
}

.resource-sow-select-title {
  font-weight: 600;
}

.resource-table-footer {
  display: flex;
  flex-direction: column;
}

.resource-table-footer span {
  padding: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
}

.resource-allct-tooltip {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-top: 10px;
  gap: 1rem;
}

.resource-matching-skills {
  font-weight: 500;
  color: var(--green);
}


.resource-alct-section-title {
  font-weight: 500;
  font-size: 1.1rem;
}

.resource-alct-section-title button {
  padding: 0;
}

.rf-header-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  padding: 0 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.rf-header-title {
  margin: 10px 0;
  font-weight: bold;

}

.rf-header-row {
  display: grid;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  grid-template-columns: 220px 1fr;
}

.rf-header-label {
  font-weight: 600;

}

.rf-header-text-label {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.rf-header-textarea {
  display: grid;
  padding: 10px 0;
  grid-template-columns: 220px 1fr;

}