.rf-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.4);
}

.rf-table th {
  background-color: #387bff;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  height: 40px;
}

.rf-table thead {
  border: 1px solid #387bff;
}

.rf-table td {
  height: 60px;
  text-align: center;
  border: 1px solid #dfdfdf;
}

#rf-percentage {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid var(--border-color-1);
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  text-align: center;
}

#rf-percentage::-webkit-inner-spin-button,
#rf-percentage::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#rf-date {
  height: 38px;
  border-radius: 5px;
  border: 1px solid var(--border-color-1);
  text-align: center;
  width: 150px;
  font-size: 14px;
}

.rf-info {
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.rf-btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
}