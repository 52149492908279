.date-picker-icon {
    top: 8px;
    position: absolute;
    right: 16px;
}


.date-picker-input {
    font-family: monospace;
    padding-right: 14px;
}

.react-datepicker__current-month {
    display: none;
}

.react-datepicker__day--outside-month {
    color: #959595;
}

.react-datepicker {
    box-shadow: 0 0 4px 3px rgb(54 54 54 / 27%), 0 1px 3px -1px rgb(221 221 221 / 26%);
}

.react-datepicker__year-option:not(:first-child):not(:last-child) {
    width: calc(25%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    line-height: 0px;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 242px;
    height: 205px;
    left: -1px;
    top: 57px;
    align-items: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #fff;
    padding: 4px;
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option--selected_month {
    background: #bad9f1;

    border-radius: 0;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected {
    display: none;
}

.react-datepicker__year-option:first-child,
.react-datepicker__year-option:last-child {
    width: 100%;
    height: 25px;
    justify-content: center;
    align-items: center;
    display: none;
}

.react-datepicker__year-option:nth-last-child(2) {
    display: none !important;
}

.react-datepicker__navigation--years-upcoming {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #7e7e7e;
    top: 0;
}

.react-datepicker__navigation--years-previous {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #7e7e7e;
    top: 0;
}

.react-datepicker__month-option {
    width: calc(33.33%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    line-height: 0px;
    border-radius: 0 !important;
}

.react-datepicker__month {
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.react-datepicker__day--selected {
    background-color: #387bff;

}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
    background-color: #f0f0f0;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
    visibility: visible !important;
    font-weight: 600;
}