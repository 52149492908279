.dashboard-container {}

.dashboard-nav-panel {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 2rem;
  position: absolute;
  max-width: 200px;
  top: 3rem;
}


.dashboard-tableau-container {
  min-height: 1050px;

  margin-left: 200px;
}