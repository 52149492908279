/* demand */
.demand-resource-row {
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    padding: 15px;
}


.demand-resource-row textarea {
    resize: vertical;
    width: 100%;
    border: 1px solid var(--border-color-1);
    min-height: 50px;
    max-width: 1024px;
    border-radius: 4px;
}



.demand-table-body .css-b62m3t-container {
    min-width: 120px;
    max-width: 350px;
    margin: auto;
    padding-left: 5px;
    padding-right: 5px;
}

.demand-table-header {
    width: 400px;
    height: 60px;
}

.demand-resource-row .css-b62m3t-container {
    margin: 0;
}

.demand-hours {
    height: 38px;
    border: 1px solid var(--border-color-1);
    border-radius: 4px;

    width: 50px;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center;
}



.demand-hours::-webkit-inner-spin-button,
.demand-hours::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.demand-table-body td {
    height: 70px !important;
}

.demand-show-less {
    display: block;
    margin-top: 10px;
    width: 80px;
    color: blue;
}

.demand-show-less:hover {
    cursor: pointer;
}



.demand-table-actions {
    display: flex;
    align-items: center;
    justify-content: center;

}

/* more info text */
.demand-header {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.demand-more-info-text {
    display: block;
    overflow-y: hidden;
    transition: all 0.5s linear;
    line-height: 1.6;

}

.demand-header-month-names {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;

}

.demand-comments {
    display: flex;
    gap: 1rem;

    width: 100%;
}

.demand-resource-select {
    display: flex;
    gap: 1rem;
    min-width: 400px;
}

.demand-row-odd {
    background-color: rgb(247 247 247);
}