.loader {
  border: 5px solid #63afff4f;
  border-radius: 50%;
  border-top: 5px solid #036ddf;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadingWrapper {
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color-4);
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dotAnimation {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: #036ddf;
  color: #036ddf;
  animation: dotAnimation 1s infinite linear alternate;
  animation-delay: .5s;
}

.dotAnimation::before,
.dotAnimation::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dotAnimation::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: #036ddf;
  color: #036ddf;
  animation: dotAnimation 1s infinite alternate;
  animation-delay: 0s;
}

.dotAnimation::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: #036ddf;
  color: #036ddf;
  animation: dotAnimation 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotAnimation {
  0% {
    background-color: #036ddf;
  }

  50%,
  100% {
    background-color: #9ed8fcb0;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 40px;
}