.initiative-header {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

}

.initiative-link {
    color: var(--blue);
    font-weight: 600;
}

.initiative-link:hover {
    text-decoration: underline;
}

/* CSS for initiative forms */
.initiative-form-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
    margin-top: 1rem;
    width: 50%;
}

.initiative-form-group {
    display: flex;
    gap: 5rem;
}

.initiative-form-label span {
    font-weight: 700;
}


.initiative-form-label textarea {
    resize: vertical;
    width: 100%;
    border: 1px solid var(--border-color-1);
    min-height: 150px;
    border-radius: 4px;
}

.initiative-form-name {
    height: 38px;
    border: 1px solid var(--border-color-1);
    border-radius: 4px;
    padding-left: 5px;
    width: 100%;
}

.initiative-form-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 2rem
}

.initiative-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    transition: 0.3s all linear;
}

.initiative-form-label>.css-b62m3t-container {
    min-width: 350px;
}

.initiative-form-label .css-g1d714-ValueContainer {
    flex-direction: column;
    align-items: flex-start;
}

.initial-form-error-msg {
    color: var(--red);
    font-weight: 400 !important;
}

@media only screen and (max-width: 1050px) {

    .initiative-form-wrapper {
        flex-wrap: wrap;
    }

    .initiative-form-group {
        width: 100%;
        gap: 2rem;
    }

    .initiative-form-label>.css-b62m3t-container {
        min-width: 100%;
    }

    .initiative-form-label {
        width: 100%;
    }

    .demand-resource-select {
        min-width: auto !important;
    }
}