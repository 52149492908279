.rmr-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
}

.rmr-home-delegate,
.rmr-home-resource {
    border-radius: 2px;
    width: 100%;
    border: 1px solid var(--border-color-1);
    min-height: 300px;
    font-size: 14px;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    padding-bottom: 1.3rem;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.rmr-home-header {
    font-size: 18px;
    text-align: center;
    margin: 1rem;
}

.rmr-home-link {
    display: flex;
    justify-content: center;
}

.rmr-home-delegate-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.rmr-home-delegate-list {
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.rmr-home-popup {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
}

.rmr-home-add-title {
    width: 130px;
    border: 1px solid #cecece;
    height: 38px;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #cecece40;
}

.rmr-home-add-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;

}

.rmr-home-add-btn {
    height: 38px;
    width: 40px;
    box-shadow: none;
    margin-left: 5px;
    overflow: hidden;
    padding: 0;
    transition: all .3s linear;
    opacity: 0.9
}

.rmr-home-add-search {
    width: 75%;
    z-index: 1;
    transition: all .3s linear;
}

.rmr-home-add-btns {
    display: flex;
    transition: all .3s linear;
}

.rmr-home-add-search .css-1s2u09g-control {
    border-radius: 0 5px 5px 0;
}

.rmr-home-resource-tableau {
    display: flex;
    justify-content: center;
}