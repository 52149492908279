@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

body {

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--bg-color-1);
  color: var(--font-color-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* Soft CSS Reset */
*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

/* colors */
/* Color naming should increase from 1. (1 as main )*/
:root {
  --font-color-1: #212121;
  --bg-color-1: #ffffff;
  --link-color-1: #03a9f4;
  --green: #32a852;
  --blue: #387bff;
  --red: #df4747;
  --grey: #5f5f5f;
  --border-color-1: #c7c7c7c4;
}

/* common css classes */

.navLink {
  width: auto;
  border: none;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  color: var(--font-color-1);
  font-family: "Montserrat", sans-serif !important;
}

.navLink-active {
  color: var(--link-color-1);
}

.navLink:hover {
  opacity: 0.9;
  color: var(--link-color-1);
}

.navLink:active {
  opacity: 1;
}

.link {
  border: none;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  color: var(--grey);
  padding: 10px 10px 10px 20px;
  width: 170px;
  /* font-family: "Montserrat", sans-serif !important; */
  border-radius: 5px;
}

.link-active {
  color: var(--blue);
  background-color: rgba(13, 110, 253, 0.1);
  cursor: none;
  font-weight: 500;
}

.link:hover {
  opacity: 0.9;
  color: var(--blue);
  background-color: rgba(13, 110, 253, 0.1);
}

.link:active {
  opacity: 1;
}

.link-regular {
  color: var(--blue);

}

.link-regular:hover {
  text-decoration: underline;

}

.btn {
  display: flex;
  height: 35px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: var(--grey);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%), 0 0 1px rgb(0 0 0 / 30%)
}

.btn:hover {
  opacity: 0.9;
}

.btn:disabled,
.btn[disabled]:hover {
  opacity: 0.5;
  cursor: auto;
}

.btn:active {
  opacity: 1;
}

.btn-green {
  background-color: var(--green);

}

.btn-blue {
  background-color: var(--blue);
}

.btn-red {
  background-color: var(--red);

}

.btn-trans {
  background-color: transparent;
  color: var(--font-color-1);
  margin: 0;
  opacity: 0.5;
  box-shadow: none;
  height: auto;
}

.flex {
  display: flex;
  gap: 10px;
}

.flex-row {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-loading-spinner {
  width: 100%;
  height: 100vh;
}

.under-construction {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh
}


.form-input {
  height: 30px;
  width: 60px;
  border: 1px solid #97979763;
  border-radius: 2px;
  padding-left: 5px;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(138, 138, 138, 0.61);
  border-radius: 3px;
  display: block;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(138, 138, 138);
}