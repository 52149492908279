.position-form-table-group-0,
.position-status {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 1rem;
    gap: 2rem;
}

.position-status {
    margin-top: 2rem;
    width: 100%;
}

.position-input-number {
    height: 38px;
    border: 1px solid var(--border-color-1);
    border-radius: 4px;
    width: 100%;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center;
    min-width: 100px;
}

.position-form-name {
    height: 38px;
    border: 1px solid var(--border-color-1);
    border-radius: 4px;
    padding-left: 5px;
    width: 100%;
    min-width: 100px;
}

.position-form-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
    width: 50%;
}

.position-form-title {
    font-weight: 500;
}

.position-form-label textarea {
    resize: vertical;
    width: 100%;
    border: 1px solid var(--border-color-1);
    min-height: 150px;
    padding: 5px;
    border-radius: 4px;
}

.position-form-label>.css-b62m3t-container {
    /* min-width: 250px; */
    width: 100%;
}

.position-form-label .css-g1d714-ValueContainer {
    flex-direction: column;
    align-items: flex-start;
}

.position-form-error-msg {
    color: var(--red);
    font-weight: 400 !important;
}

.form-header {
    font-size: 20px;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.position-table-field {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%), 0 0 1px rgb(0 0 0 / 30%);
    box-shadow: 0 0 1px 0 rgb(9 30 66 / 31%), 0 1px 3px -1px rgb(9 30 66 / 25%);
}

.position-table-field th {
    background-color: #387bff;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    height: 40px;
}

.position-table-field thead {
    border: 1px solid #387bff;
}

.position-table-field td {
    height: 60px;
    text-align: center;
    border: 1px solid #dfdfdf;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}

.position-form-section {
    width: 100%;
}

.position-form-table-group,
.position-form-table-group-0 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 2rem;
}

.position-form-table-group-2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 2rem;
}



.position-form-table-group-1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 2rem;
    flex-direction: column;
}

.position-table-field .css-b62m3t-container {
    min-width: 100px;
    max-width: 650px;
    margin: auto;
}

.posForm-btn-group {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
}

.select-placeholder {
    height: 38px;
    border-radius: 4px;
    border: 1px solid var(--border-color-1);
    display: flex;
    justify-content: center;
    min-width: 250px;
    max-width: 650px;
    margin: auto;
    position: relative;
}

.position-form-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}

.position-form-top>.position-form-label {
    width: 100%;
}

.rmr-position-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

@media only screen and (max-width: 1050px) {

    .position-form-table-group-2,
    .position-form-table-group,
    .position-form-table-group-0 {
        flex-wrap: wrap;
    }

    .position-form-table-group-0>.position-form-top,
    .position-form-label {
        width: 100%;
    }

}

#rf-date {
    letter-spacing: 1.1px;
}